import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// TODO: replace react-sticky lib with react-sticky-el lib
import { StickyContainer, Sticky } from 'react-sticky';
import Moment from '../../../modules/react-moment';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import qs from 'qs';
import { stringifyQueryParams, isFunction } from '../../../helpers';

import MobileSidebar from '../MobileSidebar/MobileSidebar';
import SidebarFilter from '../SidebarFilter/SidebarFilter';
import NavigationDropdown from './NavigationDropdown/NavigationDropdown';
import Link from '../Link/Link';
import AgendaDaySelectors from '../../sections/AgendaDaySelectors/AgendaDaySelectors';

import './FilterableContent.scss';

const MAIN_MENU_HEIGHT = 64;
const NAVIGATION_HEIGHT = 45;

export class FilterableContent extends Component {
  static propTypes = {
    fluid: PropTypes.bool,
    mode: PropTypes.string,
    showTopBar: PropTypes.bool,
    heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    pinnedDates: PropTypes.array,
    primaryFilter: PropTypes.array,
    secondaryFilter: PropTypes.array,
    allowSearch: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
    children: PropTypes.node,
    match: PropTypes.object,
    history: PropTypes.object,
    viewType: PropTypes.string,
    sortBy: PropTypes.oneOf(['time', 'streams']),
    showViewSwitcher: PropTypes.bool,
    selectedDay: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    sidebarHidden: PropTypes.bool,
    toggleViewSettings: PropTypes.func,
    changeIsSearchFilterFlag: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.componentRef = React.createRef();
    this.contentRef = React.createRef();
    this.stickySidebarRef = React.createRef();

    let searchFilterValue = '';
    const checkedFilters = this.getCheckedFiltersFromLocation();

    if (checkedFilters.searchInput) {
      searchFilterValue = decodeURIComponent(checkedFilters.searchInput).trim();
      delete checkedFilters.searchInput;
      isFunction(props.changeIsSearchFilterFlag) &&
        searchFilterValue &&
        props.changeIsSearchFilterFlag(true);
    }

    this.state = {
      searchFilter: searchFilterValue,
      isAgenda: props.mode === 'agenda',
      navigationHeight: props.showTopBar ? NAVIGATION_HEIGHT : 0,
    };

    this.toggleSettings = this.toggleSettings.bind(this);
    this.checkNavigationHeight = this.checkNavigationHeight.bind(this);
    this.getStickyOffset = this.getStickyOffset.bind(this);
  }

  componentDidMount() {
    this.contentRef.current.style.minHeight =
      this.stickySidebarRef.current.querySelector('.sticky-content')
        .clientHeight + 'px';

    this.checkNavigationHeight();
    window.addEventListener('resize', this.checkNavigationHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkNavigationHeight);
  }

  checkNavigationHeight() {
    const navigationElement = this.componentRef.current.querySelector(
      '.c-filterable-content__navigation',
    );

    this.setState({
      navigationHeight: navigationElement
        ? navigationElement.clientHeight
        : NAVIGATION_HEIGHT,
    });
  }

  getCheckedFiltersFromLocation() {
    const checkedFilters = qs.parse(
      this.props.history.location.search.slice(1),
    );

    Object.keys(checkedFilters).forEach((key) => {
      if (typeof checkedFilters[key] === 'string') {
        checkedFilters[key] = [checkedFilters[key]];
      }
    });

    return checkedFilters;
  }

  updateContent() {
    let outputFilters = this.getCheckedFiltersFromLocation();
    outputFilters = Object.assign(outputFilters, {
      searchInput: this.state.searchFilter,
    });

    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(outputFilters);
    }

    document.documentElement.scrollTop =
      this.componentRef.current.offsetTop - MAIN_MENU_HEIGHT;
  }

  onSearchChange(value) {
    const { changeIsSearchFilterFlag, history } = this.props;
    const event = new CustomEvent('ClearAgendaFilters');
    document.dispatchEvent(event);

    this.setState({
      searchFilter: value,
    });

    this.toggleSettings('viewTypeSearch');

    isFunction(changeIsSearchFilterFlag) && changeIsSearchFilterFlag(!!value);

    history.push(
      stringifyQueryParams({
        searchInput: value,
      }),
    );

    clearTimeout(window.searchTimeout);

    window.searchTimeout = setTimeout(() => this.updateContent(), 400);
  }

  toggleSettings(setting, value) {
    if (
      this.props.toggleViewSettings &&
      typeof this.props.toggleViewSettings === 'function'
    ) {
      this.props.toggleViewSettings(setting, value);
    }
  }

  onSidebarEnter() {
    this.stickySidebarRef.current.querySelector(
      '.sticky-content',
    ).style.transform = 'initial';
  }

  onSidebarLeave() {
    this.stickySidebarRef.current.querySelector(
      '.sticky-content',
    ).style.transform = 'translateZ(0px)';
  }

  getStickyOffset() {
    const {
      pageConfig: {
        elementsProperties: {
          multiLevelNavMenu: { height: mainMenuHeight = MAIN_MENU_HEIGHT } = {},
        } = {},
      } = {},
    } = this.props;

    return -mainMenuHeight;
  }

  modifyFixedSidebarStyleObject({ isSticky, style }) {
    const {
      pageConfig: {
        elementsProperties: {
          multiLevelNavMenu: {
            height: mainMenuHeight,
            bottom: navigationOffsetTop,
          } = {},
        } = {},
      } = {},
    } = this.props;

    return Object.assign(cloneDeep(style), {
      top: isSticky
        ? (mainMenuHeight || MAIN_MENU_HEIGHT) + NAVIGATION_HEIGHT
        : navigationOffsetTop,
    });
  }

  render() {
    const {
      heading,
      fluid = false,
      showTopBar = false,
      pinnedDates,
      primaryFilter,
      secondaryFilter,
      allowSearch = false,
      children,
      selectedDay,
      showViewSwitcher = false,
      viewType,
      sortBy,
      sidebarHidden,
      t,
    } = this.props;

    const {
      pageConfig: {
        elementsProperties: {
          multiLevelNavMenu: { height: mainMenuHeight = MAIN_MENU_HEIGHT } = {},
        } = {},
      } = {},
    } = this.props;

    const locale = this.props.i18n.language;
    const { searchFilter, isAgenda } = this.state;

    const primaryFilterAvailable = primaryFilter && primaryFilter.length > 0;
    const secondaryFilterAvailable =
      secondaryFilter && secondaryFilter.length > 0;

    let isFilter = 0;
    merge(primaryFilter, secondaryFilter).forEach((e) => {
      isFilter += e.items.length;
    });

    const showFiltersKey = isFilter
      ? isMobile
        ? 'event.agenda.show-filter'
        : 'event.agenda.show-search-filter'
      : 'event.agenda.show-search';

    const activeDayNumber = (this.props.match.params.dayNumber || 1).toString();

    const PrimaryFilterComponent = (
      <SidebarFilter
        extClass="hidden-xs"
        filters={primaryFilter}
        onChange={() => this.updateContent()}
        searchFilter={searchFilter}
      >
        {allowSearch && (
          <div className="c-sidebar-filter__content-search">
            <div className="c-sidebar-filter__content-input">
              <input
                type="text"
                value={searchFilter}
                onChange={(event) => this.onSearchChange(event.target.value)}
                placeholder={t('event.search')}
              />
              {!searchFilter && <i className="material-icons">search</i>}
              {!!searchFilter && (
                <i
                  className="material-icons close"
                  onClick={() => this.onSearchChange('')}
                >
                  close
                </i>
              )}
            </div>
          </div>
        )}
      </SidebarFilter>
    );

    return (
      <div
        ref={this.componentRef}
        className={`c-filterable-content sidebar-${
          sidebarHidden ? 'hidden' : 'visible'
        }`}
      >
        {pinnedDates && (
          <div className="c-filterable-content__day-filter">
            <div className={`container${fluid ? '-fluid' : ''} h-100`}>
              <div className="row">
                <div className="col-xs-12">
                  <ul>
                    {pinnedDates.map((item, index) => (
                      <li
                        key={index}
                        className={
                          activeDayNumber === item.dayNumber.toString()
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to={`/agenda/${item.dayNumber}`} event>
                          <Moment
                            parse="YYYY-MM-DD"
                            format="MMM DD"
                            locale={locale}
                          >
                            {item.date}
                          </Moment>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <StickyContainer className="sticky-container">
          <Sticky
            topOffset={-mainMenuHeight}
            className={`sticky-element ${
              !showTopBar && 'sticky-element--desktop-hide'
            }`}
          >
            {({ style, isSticky }) => (
              <div
                className={`c-filterable-content__navigation c-filterable-content__navigation--${
                  fluid ? 'fluid' : 'fixed'
                } ${isSticky ? 'fixed' : ''}`}
                style={isSticky ? style : {}}
              >
                <div className={`container${fluid ? '-fluid' : ''} h-100`}>
                  <div className="row body-width__row">
                    <div className="col-xs-12">
                      <div className="c-filterable-content__navigation__body">
                        <div
                          className="col-sm-3 c-filterable-content__navigation__body__column c-filterable-content__navigation__body__column--left"
                          style={{
                            display:
                              primaryFilterAvailable || allowSearch
                                ? null
                                : 'none',
                          }}
                        >
                          <div className="navigation-item visible-xs">
                            {!isAgenda && (
                              <React.Fragment>
                                {PrimaryFilterComponent}
                              </React.Fragment>
                            )}
                            {isAgenda && (
                              <div
                                onClick={(event) =>
                                  MobileSidebar.openSecondarySidebar(event)
                                }
                                className="toggle"
                              >
                                {selectedDay}
                                <i className="material-icons">
                                  keyboard_arrow_right
                                </i>
                              </div>
                            )}
                          </div>
                          <div
                            className="navigation-item hidden-xs"
                            style={{
                              display: sidebarHidden ? 'inline-block' : 'none',
                            }}
                          >
                            <div
                              onClick={() =>
                                this.toggleSettings('sidebarHidden')
                              }
                              className="toggle"
                            >
                              <React.Fragment>
                                {t(showFiltersKey)}{' '}
                                <i className="material-icons">
                                  keyboard_arrow_right
                                </i>
                              </React.Fragment>
                            </div>
                          </div>
                          <div
                            className="navigation-item hidden-xs"
                            style={{
                              display: sidebarHidden ? 'none' : 'inline-block',
                            }}
                          >
                            <div
                              onClick={() =>
                                this.toggleSettings('sidebarHidden')
                              }
                              className="toggle"
                            >
                              <React.Fragment>
                                <i className="material-icons">
                                  keyboard_arrow_left
                                </i>{' '}
                                {t('event.agenda.hide-filter')}
                              </React.Fragment>
                            </div>
                          </div>
                          <div className="navigation-item navigation-item--day-filter hidden-xs">
                            {secondaryFilterAvailable && (
                              <div
                                onClick={(event) =>
                                  MobileSidebar.openSecondarySidebar(event)
                                }
                                className="toggle"
                              >
                                {t('event.agenda.days')}{' '}
                                <i className="material-icons">
                                  keyboard_arrow_right
                                </i>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="c-filterable-content__navigation__body__column c-filterable-content__navigation__body__column--center">
                          {heading}
                        </div>
                        <div className="c-filterable-content__navigation__body__column c-filterable-content__navigation__body__column--right">
                          {isAgenda && (
                            <div className="navigation-item hidden-xs">
                              {viewType === 'list' && showViewSwitcher && (
                                <NavigationDropdown
                                  value={sortBy}
                                  onChange={(value) =>
                                    this.toggleSettings('sortBy', value)
                                  }
                                  options={{
                                    time: 'event.agenda.sort.byTime',
                                    streams: 'event.agenda.sort.byStream',
                                  }}
                                />
                              )}
                            </div>
                          )}
                          {!searchFilter && isAgenda && showViewSwitcher && (
                            <div className="navigation-item hidden-xs">
                              <div
                                onClick={(value) =>
                                  this.toggleSettings('viewType', value)
                                }
                                className="toggle toggle--view-type"
                              >
                                {viewType === 'list' && (
                                  <React.Fragment>
                                    {t('event.agenda.view.GRID')}
                                    <i className="material-icons">
                                      view_column
                                    </i>
                                  </React.Fragment>
                                )}
                                {viewType === 'grid' && (
                                  <React.Fragment>
                                    {t('event.agenda.view.DEFAULT')}
                                    <i className="material-icons">view_list</i>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          )}
                          {primaryFilterAvailable && (
                            <div className="navigation-item visible-xs">
                              <div
                                onClick={(event) =>
                                  MobileSidebar.openPrimarySidebar(event)
                                }
                                className="toggle"
                              >
                                <i className="material-icons">
                                  keyboard_arrow_left
                                </i>
                                {t(showFiltersKey)}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {isAgenda && allowSearch && (
                      <div className="col-xs-12">
                        <div className="navigation-item visible-xs">
                          <React.Fragment>
                            {PrimaryFilterComponent}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Sticky>

          {isAgenda && (
            <div className="mobile-current-day-header">{heading}</div>
          )}

          <div className={`container${fluid ? '-fluid' : ''} h-100`}>
            <div className="row body-width__row">
              <div className="col-xs-12">
                <div className="c-filterable-content__container">
                  <div
                    className={`col-sm-3 c-filterable-content__sidebar c-filterable-content__sidebar--${
                      fluid ? 'fluid' : 'fixed'
                    }`}
                    style={{
                      display:
                        primaryFilterAvailable || allowSearch ? null : 'none',
                    }}
                    ref={this.stickySidebarRef}
                  >
                    <Sticky
                      topOffset={-mainMenuHeight}
                      disableCompensation
                      className="sticky-element"
                    >
                      {(options) => (
                        <div
                          onMouseEnter={(event) => this.onSidebarEnter(event)}
                          onMouseLeave={() => this.onSidebarLeave()}
                          className="sticky-content"
                          style={this.modifyFixedSidebarStyleObject(options)}
                        >
                          {PrimaryFilterComponent}
                        </div>
                      )}
                    </Sticky>
                  </div>
                  <div
                    className={`col-sm-12 c-filterable-content__content c-filterable-content__content--${
                      fluid ? 'fluid' : 'fixed'
                    }`}
                    ref={this.contentRef}
                  >
                    {Children.map(children, (child) =>
                      cloneElement(child, {
                        viewType,
                        sortBy,
                        sidebarIsHidden: sidebarHidden,
                      }),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StickyContainer>
        {primaryFilterAvailable && (
          <MobileSidebar type="primary" title={t('event.agenda.filter')}>
            <SidebarFilter
              extClass="mobile-sidebar"
              filters={primaryFilter}
              onChange={() => this.updateContent()}
              searchFilter={searchFilter}
            />
          </MobileSidebar>
        )}
        {secondaryFilterAvailable && (
          <MobileSidebar type="secondary">
            <SidebarFilter
              extClass="mobile-sidebar"
              filters={secondaryFilter}
              onChange={() => this.updateContent()}
              searchFilter={searchFilter}
            />
          </MobileSidebar>
        )}
        {!secondaryFilterAvailable && isAgenda && (
          <MobileSidebar type="secondary" title={t('event.agenda.choose-day')}>
            <AgendaDaySelectors mobile />
          </MobileSidebar>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default withTranslation()(
  connect(mapStateToProps)(withRouter(FilterableContent)),
);
